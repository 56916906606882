
#team-title {
    text-align: left;
    display: block;
    width: 13.5rem;
    padding-bottom: 0.75%;
    border-bottom: 4px solid;
}
/* body {
    max-width:80vw;
} */
.team-body {
    background-color: #ffffff;
    text-align: center;
    display: grid;
    grid-template-rows: 25rem 25rem 25rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.phd-first-row {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    /* padding-top: 1%; */
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
}

.phd-second-row {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
}

.team-card {
    align-items: center;
}

.founder-card {
    align-items: center;
    padding-top: 5%;
    padding-left: 2%;
    padding-right: 2%;
}

a {
    color: blue; /* Set the color of the link */
    text-decoration: underline; /* Underline the link */
    cursor: pointer; /* Change cursor on hover */
  }

  .grid-item {
    /* style for the items within the grid */
  }
  
.team-name {
    padding-top: 2%;
    color: black;
    font-size: 1.5rem;

}

.alumni {
    background-color: #ffffff;
    text-align: left;
    padding-top: 2%;
    padding-left: 5rem;
    padding-right: 4rem;
    padding-bottom: 3%;
}

.alumni-list {
    list-style-type: square;
}

#alumni-title {
    text-align: left;
    width: 7rem;
    padding-bottom: 0.75%;
    border-bottom: 4px solid;
}
.black {
    color: black;
    background-color: #ffffff;
}
h3, h2 {
    text-align: center;
}
.circular--square { 
    border-radius: 50%; 
    width: 10rem;
    height: 10rem;
}

tr, th, span, table{
    text-align: center;
    vertical-align: middle;
}

.teamText {
    margin-left: 15%;
    margin-right: 15%;
    font-size: 1rem;
    font-weight: normal;
    color: black;
}
h5 {
    font-weight: bold;
}
* {
    font-family: Georgia, 'Times New Roman', Times, serif;
}
#orange_underline {
    padding-bottom: 0.75%;
    border-bottom: 4px solid #E4572E;
   }

.underline {
    padding-bottom: 0.75%;
    border-bottom: 4px solid;
   }
/* h3, h4, ul, p {
    text-align: left; /* Center the list items horizontally
    display: block; /*Display the image as a block element
    float: left; /* Float the image to the left
} */


.image {
	margin-left: 10%;
	display: block;
}

/* .project-img {
    height: 35rem;
    width: 30rem;
    aspect-ratio: auto;

} */

.container {
    display: grid;
    grid-template-columns: 41% 41% 41%;
    align-items: left;
    margin-left: 1.5%;
    /* grid-template-rows: 3em 1.6em 1.6em; */

}
.research-grid-container {
    display: grid;
    grid-template-columns: 1.25fr 0.75fr;
    align-items: left;
    grid-gap: 0.7%; /* set gap between the columns */
  }

.projects-body {
    height: 100%;
    width: 100%;
    font-size: 1.2rem;
    padding-left: 6rem;
    padding-right: 6rem;
}

.innovations-body {
    height: 100%;
    width: 100%;
    font-size: 1.2rem;
    padding-left: 7rem;
    padding-right: 7rem;
}

  .innovations-container {
    display: flex;
    /* grid-template-rows: 50% 50%; */
    align-items: left;
    grid-gap: 0.7%; /* set gap between the columns */
    padding-bottom: 5rem;
    flex-direction: column;
    justify-content: space-evenly;

  }

  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

.overline {
    padding-top: 0.75%;
    border-top: 4px solid;
   }

.blog-body {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 6rem;
    padding-right: 6rem;

}

.blog-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.publication-body {
    height: 100%;
    font-size: 1.2rem;
    padding-left: 7rem;
    padding-right: 7rem;
}

#sponsor-title {
    color: black;
    text-align: left;
    margin-left: 3%;
    font-size: 2.5rem;
}

@media screen and (max-width: 1150px) {
    .research-grid-container {
        display: inline;
    
      }
}

@media screen and (max-width: 950px) {
    .team-body {
        display: grid;
        grid-template-rows: 25rem 100rem 75rem;
    }
    .phd-first-row {
        display: grid;
        grid-template-rows: 25rem 25rem 25rem 25rem;
        grid-template-columns: 100%;
    }

    .phd-second-row {
        display: grid;
        grid-template-rows: 25rem 25rem 25rem;
        grid-template-columns: 100%;
    }

    .blog-body {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .publication-body {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .projects-body {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .innovations-body {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .alumni {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #sponsor-title {
        font-size: 1.5rem;
    }
    
}
/* .container div:nth-child(1), div:nth-child(6) {
    background-color: #B8336A;
}

.container div:nth-child(2), div:nth-child(7) {
    background-color: #726DA8;
}

.container div:nth-child(3), div:nth-child(8) {
    background-color: #7D8CC4;
}

.container div:nth-child(4) {
    background-color: #A0D2DB;
}

.container div:nth-child(5) {
    background-color: #C490D1;
} */