* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Sans', sans-serif;
}
.info {
	display: flex;
  font-weight: normal;
	flex-direction: column;
	padding: 3%;
	margin: 0 2%;
	align-self: center;
  color: #fff;
  text-align: left;
}
.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}



/* .list-row {
  background:#f4f4f4;
  display:inline-block;
  border:2px solid red;}

.list-left {
  width:auto;
  padding:10px;
  float:left;
  border: 2px solid blue;}

.list-right {
  padding:10px;
  float:right;
  border:2px solid green;} */