.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* .contact-info {
  padding-left: .5rem;
} */

.contact-grid {
  display: grid;
  grid-template-columns: 6% 15% 6% 24% 6% 19% 6% 19%;
  grid-gap: .5rem;
  background-color: #242424;
  color: white;
  padding-right: 5%;

}

.contact-grid-item {
  align-items: center;
  display: flex;
  z-index: 999;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}
.logo-grid-container {
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;
  grid-template-rows: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

#footer-logo {
  width: 15rem;
  height: 10rem;

}

#UOS-logo {
  width: 9rem;
  height: 2.7rem;
  justify-self: "right";
  padding-right:"0.5rem"
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8rem;
  padding-bottom: 0.8rem;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

#location {
  width: 200px;
  height: 93px;
}

@media screen and (max-width: 900px) {
  .contact-grid {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 25% 25% 25% 25%;
    padding-right: 0%;
    grid-gap: .5rem;
    background-color: #242424;
    color: white;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .social-logo {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  #UOS-logo {
    width: 9rem;
    height: 2.7rem;
    padding-right: 0%;
    justify-self: center;
  }

  .logo-grid-container {
    grid-template-columns: 100%;
    grid-template-rows: 33% 33% 33%;
  }

  #footer-logo {
    width: 12rem;
    height: 9rem;
  }
}


