/* ALL IMAGES ARE OF RATIO 2500pxH 6000pxW (25:60)*/

.posts-container {
	/* display: flex; */
	justify-content: center;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
}
.image {
	padding-left: 3rem;
	padding-right: 3rem;

	min-width: 30rem;

	display: block;
}
#highlight_video_wrapper {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	display: flex;
	position: relative;
	padding-left: 3rem;
	padding-right: 3rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}