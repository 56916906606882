.about-us-container {
    display: grid;
    grid-template-columns: 33% 0.5% 33% 0.5% 33%;  
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3rem;
    padding-right: 3rem;
}

* {
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.about-us-content {
    padding-left: 12%;
    padding-right: 10%;
    padding-top: 5%;
    font-size: 1.2rem;
}

.splitter {
    border-left: 2px solid #333333;
    height: 85%;
    position: relative;
    top: 3rem;
}

.about-us-titles {
    font-size: 2.5rem;
    font-weight: bold;
    color: #E4572E;
}

.orange_underline {
    padding-bottom: 0.75%;
    border-bottom: 4px solid #E4572E;
   }

@media screen and (max-width: 768px) {
    .about-us-container {
        display: grid;
        grid-template-rows: 33% 0.5% 33% 0.5% 33%;
        grid-template-columns: 100% ;
        padding-top: 3%;
        padding-bottom: 3%;
    }

    .about-us-titles {
        font-size: 2.5rem;
        font-weight: bold;
        color: #E4572E;
        padding-top: 3%;
    }
    
    .splitter {
        width: 85%;
        height: 50%;
        background-color: black;
        top: 1rem;
        left: 7%;
        

    }

  }
  