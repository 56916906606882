/* .post-container {
	background: #bebebe;
	display: flex;
	flex-direction: column;
	padding: 3%;
	margin: 0 2%;
	align-self: center;

} */
/* .heading {
	height: 126px;
	text-align: center;
	display: flex;
	align-items: center;15
	align-self: center;
} */

.highlight-container {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.post-grid-container {
	display: grid;
    /* color: rgb(0, 0, 0);
    background-color: #ffffff; */
    grid-template-columns: 30% 70%;
	gap: 2rem;
	padding-bottom: 1rem;
	padding-top: 1rem;
	padding-left: 3rem;
	padding-right: 3rem;
}

.innovations-post-grid-container {
	display: grid;
	color: rgb(0, 0, 0);
	background-color: #ffffff;/*"#1C1B1B",*/
	grid-template-columns: 100%;
	grid-template-rows: 1fr 1fr;
}

.posts-media-wrapper {
	width: clamp(21rem, 80%, 25rem);
    height: clamp(14rem, 50%, 20rem);
	position: relative;
	overflow: hidden;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.post-media {
	height: 100%;
    width: 100%;
    animation-duration: 0.1s;
	margin-left: 7%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.posts-image {
	/* ALL IMAGES ARE OF RATIO 2500pxH 6000pxW (25:60)*/
	/* position: absolute; */
	/* top: 0;
	left: 0; */
	width: 100%;
	/* height: 100%; */
}

.posts-video {
	/* ALL IMAGES ARE OF RATIO 2500pxH 6000pxW (25:60)*/
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.heading {
	font-size: 1.7rem;
}

.post-info {
	text-align: justify;
	padding-left: 2rem;
    padding-right: 6rem;
    font-size: 1.2rem;
	animation-duration: 0.1s; /* don't forget to set a duration! */
}

@media screen and (max-width: 1200px) {
    .post-grid-container {
		display: grid;
      	grid-template-columns: 100%;
    	grid-template-rows: 1fr 1fr;
		gap: 0;
	}

	.heading {
		font-size: 1.4rem;
	}

	.highlight-container {
		padding: 0;
	}

  	.post-info {
		text-align: justify;
		padding-top: 0;
		padding-bottom: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		font-size: 1rem;
		animation-duration: 0.1s;
		margin-top: 0; /* add this to reduce space above the text */

	}

	.post-media {
		margin-left: 0;
		padding-bottom: 3%;
		display: flex;
		justify-content: center;
		align-items: center;
		animation-duration: 0.1s; /* don't forget to set a duration! */
	}

	.posts-media-wrapper {
		width: clamp(21rem, 80%, 25rem);
    	height: clamp(14rem, 70%, 17rem);
	}
  }

@media screen and (max-width: 480px) {
	.post-grid-container {
		display: grid;
      	color: rgb(0, 0, 0);
      	background-color: #ffffff;/*"#1C1B1B",*/
      	grid-template-columns: 100%;
    	grid-template-rows: .8fr 1fr;
		
	}

	.posts-media-wrapper {
		width: clamp(19rem, 80%, 25rem);
    	height: clamp(14rem, 70%, 15rem);
		margin-top: -4rem;
	}
	.post-info {
		text-align: left;
		margin-top: -2rem; /* add this to reduce space above the text */
		margin-bottom: -1rem;
	}
}