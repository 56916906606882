#vision-grid-container {
    display: grid;
    color: white;
    background-color: #333333;/*"#1C1B1B",*/
    grid-template-columns: 50% 50%;
}
  
#vision-content {
  text-align: center;
  padding-top: 7%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 7%;
  font-size: 1.2rem;
  animation-duration: 0.1s; /* don't forget to set a duration! */
}

* {
  font-family: Georgia, 'Times New Roman', Times, serif;
}
  
  /* .video_wrapper {
      width: 400px;
      height: 300px;
      position: relative;
      left: 100px;
      down: 150px;
  } */
  
#vision-media-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

#video_wrapper {
  width: clamp(20rem, 80%, 35rem);
  height: clamp(13rem, 80%, 20rem);
  position: relative;
  overflow: hidden;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vision-video {
  /* border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(20rem, 30rem, 35rem);
  height: clamp(13rem, 15rem, 20rem);
  object-fit: cover;
  z-index: 20; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1170px) {
  #vision-grid-container {
    display: grid;
    color: white;
    background-color: #333333;/*"#1C1B1B",*/
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
  }

  #video-wrapper {
    width: clamp(20rem, 80%, 35rem);
    height: clamp(13rem, 80%, 20rem);
  }

  #vision-content {
    padding-bottom: 0;
  }



}